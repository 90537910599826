import AlphaFilters from '@nematis/wp-alpha/assets/js/abstracts/classes/alpha-filters'
import merge from 'lodash-es/merge'

// import third parties
AlphaFilters.addFilter('load.thirdParty', (importer, thirdPartyName) => {
  switch (thirdPartyName) {
    // add your third party here
    // case 'archive-my_cpt':
    case 'single-accommodations':
      return () => import(/* webpackChunkName: "third-party-[request]" */ `../third-party/${thirdPartyName}`)
    default:
      return importer
  }
}, 'child')

// import Vue apps
AlphaFilters.addFilter('load.petiteVue.apps', (importer, appFileName) => {
  switch (appFileName) {
    // add apps to dynamically import here (files starting with _ won't be bundled in separate files)
    // note: these files are optional, you can also add app data to an existing entrypoint if app is always loaded
    // case 'foo-bar':
    //   return () => import(/* webpackExclude: /\/_/ */ /* webpackChunkName: "vue-app-[request]" */ `../vue-apps/${appFileName}`)
    default:
      return importer
  }
}, 'child')

// add data to Vue apps
AlphaFilters.addFilter('app.petiteVue.data', (data, appName, id, el) => {
  data = merge(data, {
    // add some data to load on all apps here...
  })

  // ... or add them for specific apps
  switch (appName) {
    // case 'myApp':
    //   data = merge(data, {})
    //   break
    default:
      break
  }

  return data
}, 'child')

// CSS
import '../../sass/components/booking-form.scss'

document.addEventListener('alpha.loaded', (e) => {
  const alphaModuleMgr = e.detail.emitter
  const alphaConfig = alphaModuleMgr.getConfig()
  const bookingSearchFormEl = document.querySelector('form.booking-search')
  if (bookingSearchFormEl) {
    // === dates limitation & sync
    const now = new Date()
    const arrivalInputEl = bookingSearchFormEl.querySelector('[name="dateStart"]')
    const departureInputEl = bookingSearchFormEl.querySelector('[name="dateEnd"]')

    // set min / max date attributes
    if (typeof alphaConfig.booking !== 'undefined') {
      const opening = new Date(alphaConfig.booking?.opening)
      const closing = new Date(alphaConfig.booking?.closing)
      let minDate = new Date()
      let maxDate = new Date()

      minDate.setDate(now.getDate() + 1)
      minDate = now < opening ? opening : minDate
      const min = minDate.toLocaleDateString('fr-CA')

      maxDate.setDate(now.getDate() + 365)
      maxDate = now < closing ? closing : maxDate
      const max = maxDate.toLocaleDateString('fr-CA')
      const defaultDepartureDate = new Date(minDate)
      defaultDepartureDate.setDate(defaultDepartureDate.getDate() + 1)

      arrivalInputEl.setAttribute('min', min)
      arrivalInputEl.setAttribute('max', max)
      arrivalInputEl.value = min
      departureInputEl.setAttribute('min', min)
      departureInputEl.setAttribute('max', max)
      departureInputEl.value = defaultDepartureDate.toLocaleDateString('fr-CA')
    }

    // keep arrival & departure dates synchronized
    arrivalInputEl.addEventListener('change', (e) => {
      if (!e.target.getAttribute('max') || e.target.value <= e.target.getAttribute('max')) {
        const departure = new Date(e.target.value)
        departure.setDate(departure.getDate() + 1)

        const departureValue = departure.toLocaleDateString('fr-CA') // format date Y-m-d
        departureInputEl.setAttribute('min', departureValue)

        if (!departureInputEl.value || departureInputEl.value <= e.target.value) {
          departureInputEl.value = departureValue
        }
      }
    })

    // handle travelers count increase / decrease
    const ctrlNumeric = bookingSearchFormEl.querySelectorAll('.ctrl')
    if (ctrlNumeric.length) {
      ctrlNumeric.forEach((ctrl) => {
        const inputEl = ctrl.parentElement.querySelector('input[type=number]')
        ctrl.addEventListener('click', () => {
          if (ctrl.classList.contains('decrease')) {
            inputEl.stepDown()
          }
          if (ctrl.classList.contains('increase')) {
            inputEl.stepUp()
          }
        })
      })
    }
  }
})

// === CSS
import '../../sass/components/booking-form.scss'

document.addEventListener('alpha.loaded', (e) => {
  const alphaModuleMgr = e.detail.emitter
  const alphaConfig = alphaModuleMgr.getConfig()

  alphaModuleMgr.addModuleEventListener('viewport', 'changed', (e) => {
    const defaultStickyHeight = 58
    const defaultDesktopStickyHeight = 90
    const formEl = document.querySelector('.booking-search.form')
    const footerEl = document.querySelector('footer')
    const isDesktop = e.viewport.is(`>=${alphaConfig.desktopBreakpoint}`)

    if (formEl) {
      const myObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          handleStickForm(formEl, !entry.isIntersecting)
        })
      })
      myObserver.observe(document.querySelector('.booking-search-form-wrapper'))
    }

    function handleStickForm(formEl, stick = true) {
      const mainBookingWrapperEl = document.querySelector('.booking-search-form-wrapper')
      const stickyFormWrapperEl = getSearchStickyFormWrapperElement()
      if (stickyFormWrapperEl) {
        if (stick) {
          stickyFormWrapperEl.appendChild(formEl)

          let resaHeight = document.querySelector('.booking-search-form-wrapper').offsetHeight
          if (!resaHeight) {
            resaHeight = isDesktop ? defaultDesktopStickyHeight : defaultStickyHeight
          }

          footerEl.style.marginBottom = `${Number(resaHeight)}px`

          stickyFormWrapperEl.classList.add('sticky-visible')
        } else {
          if (mainBookingWrapperEl) {
            mainBookingWrapperEl.appendChild(formEl)
          }

          stickyFormWrapperEl.classList.remove('sticky-visible')

          footerEl.style.marginBottom = ''
        }
        // handle mobile display controls
        const mobileControls = stickyFormWrapperEl.querySelectorAll('.sticky-mobile-control')
        mobileControls.forEach((stickyMobileControlEl) => {
          if (!stickyMobileControlEl.classList.contains('control-initialize')) {
            stickyMobileControlEl.classList.add('control-initialize')
            stickyMobileControlEl.addEventListener('click', (e) => {
              e.preventDefault()

              stickyFormWrapperEl.classList.toggle('sticky-open')
              if (stickyFormWrapperEl.classList.contains('sticky-open')) {
                document.querySelector('.booking-sticky-form-header').classList.remove('bg-primary')
              } else {
                document.querySelector('.booking-sticky-form-header').classList.add('bg-primary')
              }
            })
          }
        })

        const mql = window.matchMedia('(max-width: 1365px)')
        const NbInput = document.querySelector('input[type=\'number\']')
        if (NbInput) {
          if (mql.matches) {
            NbInput.removeAttribute('readonly')
          } else {
            NbInput.setAttribute('readonly', 'readonly')
          }
        }
      }
    }

    function getSearchStickyFormWrapperElement() {
      let wrapperEl = document.querySelector('.booking-search-sticky-wrapper')
      if (!wrapperEl) {
        wrapperEl = _buildSearchStickyFormWrapperElement()
        document.body.appendChild(wrapperEl)
      }

      return wrapperEl
    }

    function _buildSearchStickyFormWrapperElement() {
      const wrapperEl = document.createElement('div')
      wrapperEl.classList.add('booking-search-sticky-wrapper', 'booking-search-form-wrapper')
      wrapperEl.setAttribute('id', 'booking-sticky-form-wrapper')

      // TODO: classes etc.
      wrapperEl.classList.add('sticky-bottom', 'shadow')

      // création du contenu pour la "modale"
      wrapperEl.innerHTML =
        '        <div class="booking-sticky-form-header bg-primary d-flex d-xl-none justify-content-between justify-content-md-center align-items-baseline">\n' +
        '            <a href="#" class="sticky-mobile-control open text-white w-100 text-center m-auto">\n' +
        '                <i class="fal fa-search me-2" aria-hidden="true"></i>\n' +
        '                <span>Réserver</span>\n' +
        '            </a>\n' +
        '\n' +
        '            <a href="#" class="sticky-mobile-control close">\n' +
        '                <i class="fal fa-times fa-2x" aria-hidden="true"></i>\n' +
        '                <span class="sr-only">Fermer</span>\n' +
        '            </a>\n' +
        '        </div>\n'
      return wrapperEl
    }
  })
})
